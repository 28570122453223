<template>
  <div class="dashboard">
    <FileExplorer></FileExplorer>
  </div>
</template>

<script>
import FileExplorer from '@/components/file-explorer/fileexplorer'
export default {
    name: 'FileExplorerPage',
    components: {
        FileExplorer
    },
    methods: {

    },
}
</script>
